var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "zp-dialog",
      attrs: {
        title: "修改密码",
        visible: _vm.value,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function (visible) {
          return _vm.$emit("input", visible)
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "80px",
            size: "small",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原密码", prop: "oldPasswd" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  minlength: 6,
                  maxlength: 18,
                  placeholder: "请输入原密码",
                },
                model: {
                  value: _vm.form.oldPasswd,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldPasswd", $$v)
                  },
                  expression: "form.oldPasswd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "新密码", prop: "newPasswd" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  minlength: 6,
                  maxlength: 18,
                  placeholder: "请输入新密码",
                },
                model: {
                  value: _vm.form.newPasswd,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newPasswd", $$v)
                  },
                  expression: "form.newPasswd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "确认密码", prop: "confirmPasswd" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  minlength: 6,
                  maxlength: 18,
                  placeholder: "请输入确认密码",
                },
                model: {
                  value: _vm.form.confirmPasswd,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "confirmPasswd", $$v)
                  },
                  expression: "form.confirmPasswd",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("input", false)
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.isSubmitting, type: "primary" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }