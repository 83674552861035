var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "menu scroll-bar-better",
      attrs: {
        "default-active": _vm.$route.path,
        "text-color": "#5c5f66",
        "active-text-color": "#057bde",
        collapse: _vm.isCollapse,
      },
      on: { select: _vm.handleSelect },
    },
    [
      _vm._l(_vm.menus, function (menu) {
        return [
          !menu.children.length
            ? _c(
                "el-menu-item",
                { key: menu.id, attrs: { index: "/" + menu.path } },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "zp-icon",
                      class: _vm.$style.icon,
                      attrs: { "aria-hidden": "true" },
                    },
                    [_c("use", { attrs: { "xlink:href": menu.icon } })]
                  ),
                  _vm._v(" "),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(menu.name)),
                  ]),
                ]
              )
            : _c(
                "el-submenu",
                { key: menu.id, attrs: { index: "/" + menu.path } },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "zp-icon",
                        class: _vm.$style.icon,
                        attrs: { "aria-hidden": "true" },
                      },
                      [_c("use", { attrs: { "xlink:href": menu.icon } })]
                    ),
                    _vm._v(" "),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(menu.name)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(menu.children, function (subMenu) {
                    return _c(
                      "el-menu-item",
                      {
                        key: subMenu.id,
                        attrs: { index: "/" + menu.path + "/" + subMenu.path },
                      },
                      [_vm._v("\n        " + _vm._s(subMenu.name) + "\n      ")]
                    )
                  }),
                ],
                2
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }