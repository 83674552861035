// 文件CDN地址
export const fileServerBaseUrl = 'https://gyhauto-fileservers.oss-cn-shenzhen.aliyuncs.com';

// 系统参数
export const sysParam = {
  token: 'GYH-ADMIN-TOKEN',
};

// 常用图片url
export const commonUrl = {};

// 热线电话
export const hotline = '0575-87605176';

// 短信模板发送状态
export const msgPushState = [
  {
    name: '全部',
    value: '',
  },
  {
    name: '发送成功',
    value: 1,
  },
  {
    name: '发送失败',
    value: 0,
  },
];

// 短信渠道
export const noteDitchList = [
  {
    value: 1,
    label: '验证码渠道',
  },
  {
    value: 2,
    label: '营销渠道',
  },
];

// 主营业务
export const mainBusinessOptions = [
  { label: '电子及智能联网', value: 1 },
  { label: '用品及改装', value: 2 },
  { label: '经销商及维修站管理', value: 3 },
  { label: '部件及组件', value: 4 },
  { label: '可替代能源及燃料', value: 5 },
  { label: '车身及喷涂', value: 6 },
  { label: '其它', value: 7 },
];

// 营业规模
export const businessScaleOptions = [
  { label: '1000w以下', value: 1 },
  { label: '1000~2000w', value: 2 },
  { label: '2000~3000w', value: 3 },
  { label: '3000~4000w', value: 4 },
  { label: '4000~5000w', value: 5 },
  { label: '5000w以上', value: 6 },
];

// 审核状态枚举列表
export const auditStatusArr = [
  {
    label: '待审核',
    value: 1,
  },
  {
    label: '审核通过',
    value: 2,
  },
  {
    label: '审核拒绝',
    value: 3,
  },
];

// banner展示位置
export const showLocations = [
  {
    label: '首页轮播',
    value: 1,
  },
  {
    label: '首页底部图',
    value: 2,
  },
];

// 申请来源
export const applySources = [
  { label: '小程序', value: 1 },
  { label: '续签', value: 2 },
];

// 申请审核状态
export const applyAuditStatus = [
  { value: '0', label: '全部' },
  { value: '1', label: '待审核', statsKey: 'toBeReviewedCount' },
  { value: '6', label: '即将到期', statsKey: 'nearExpirationCount' },
  { value: '2', label: '已通过' },
  { value: '4', label: '已过期' },
  { value: '3', label: '已拒绝' },
  { value: '5', label: '已取消' },
];

// 展位楼层
export const boothFloors = [
  { label: '一层', value: 1 },
  { label: '二层', value: 2 },
  { label: '三层', value: 3 },
];

// 展位选座状态
export const boothSelectStatus = [
  { label: '已选', value: 1 },
  { label: '未选', value: 0 },
];

// 展位锁定状态
export const boothLockStatus = [
  { label: '不锁定', value: 1 },
  { label: '永久锁定', value: 3 },
  { label: '固定时长', value: 2 },
];
